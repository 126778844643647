<template>
  <div class="detected">
    <div class="modal__body detected__body">
      <div class="detected__title detected__title-red">
        {{ title }}
      </div>
      <div class="detected__text">
        {{ text }}
      </div>
      <div class="detected__divider" />
    </div>
    <div class="detected__footer">
      <button
        class="button button_secondary button_md detected__capitalized-button"
        @click="onMoveCalibration"
      >
        I will move to a quieter location
      </button>
      <div class="detected__button-divider">or</div>
      <button
        class="button button_secondary button_md detected__capitalized-button"
        @click="onClose"
      >
        I will speak louder
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { saveLog } from "@/modules/logger";
import {
  MSG_DETECTED_NOISE_TITLE,
  MSG_DETECTED_NOISE_TEXT,
  STORE_SET_PASSED,
  ROUTE_AUDIO_CALIBRATION,
  ROUTE_SUSTAINED_AAH,
} from "@/constants";
export default {
  props: {
    options: { type: Object, required: true },
  },
  data() {
    return {
      title: MSG_DETECTED_NOISE_TITLE,
      text: MSG_DETECTED_NOISE_TEXT,
    };
  },
  methods: {
    ...mapActions([STORE_SET_PASSED]),
    async onClose() {
      this.options.deleteAction();
      saveLog("audio-quality-popup", {msg: "Speak louder selected"});
      await this[STORE_SET_PASSED]({ [ROUTE_SUSTAINED_AAH]: false });
      this.$router.push({ name: ROUTE_SUSTAINED_AAH });
      this.$emit("close");
    },
    async onMoveCalibration() {
      await this[STORE_SET_PASSED]({ [ROUTE_AUDIO_CALIBRATION]: false });
      this.$router.push({ name: ROUTE_AUDIO_CALIBRATION });
      saveLog("audio-quality-popup", {msg: "Move to quieter location selected"});
      this.$emit("close");
    },
  },
};
</script>
